import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  offeringNotAvailable: {
    id: "Appointment.AppointmentBookingContext.offeringNotAvailable",
    defaultMessage: "This offering is not available",
  },
});

export const useTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    offeringNotAvailable: formatMessage(messages.offeringNotAvailable),
  };
};
