import Big from "big.js";
import React, {
  FC,
  ReactNode,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { useSessionStorage } from "react-use";

import { SelectedOfferingSide } from "@Shape-Digital/kudzu-data/lib/types/common";
import { useSearchParams } from "react-router-dom";

import useSystemConfig from "../../../hooks/useSystemConfig";
import {
  CenterState,
  CenterServicesState,
  AddOnsState,
  DateState,
  TimeSlotState,
  CenterServiceGroupId,
  SelectedCenterService,
} from "./types";
import useBookingAddOns from "./useBookingAddOns";
import useBookingAvailableTimeSlots from "./useBookingAvailableTimeSlots";
import useBookingCenters from "./useBookingCenters";
import useBookingCenterServices from "./useBookingCenterServices";
import useBookingOfferings from "./useBookingOfferings";
import useBookingPackages from "./useBookingPackages";
import { getStorageAppointment } from "./storageAppointmentHelpers";
import CircularProgress from "../../Unknown/CircularProgress";
import Box from "../../Unknown/Box";
import { useAuthContext } from "../../Auth/AuthContextProvider";
import {
  SUPABASE_CENTER_USER_TOKEN_KEY,
  OVERRIDDEN_PRICE_KEY,
  OVERRIDDEN_TIME_KEY,
  OVERRIDDEN_DEPOSIT_KEY,
} from "../../../common/constants";
import { useUIContext } from "../../Unknown/UIContext";
import { useTranslations } from "./translations";

interface AppointmentBookingContextProps {
  centers: ReturnType<typeof useBookingCenters>;
  offerings: ReturnType<typeof useBookingOfferings>;
  packages: ReturnType<typeof useBookingPackages>;
  addOns: ReturnType<typeof useBookingAddOns>;
  availableTimeSlots: ReturnType<typeof useBookingAvailableTimeSlots>;
  centerServices: ReturnType<typeof useBookingCenterServices>;

  selectedCenter: CenterState;
  selectedCenterServices: CenterServicesState;
  selectedAddOns: AddOnsState;
  selectedDate: DateState;
  selectedTimeSlot: TimeSlotState;

  selectedMonth: Date;
  setSelectedMonth: (value: Date) => void;

  onCenterChange: (value: CenterState) => void;
  onCenterServicesChange: Dispatch<SetStateAction<CenterServicesState>>;
  onAddOnsChange: Dispatch<SetStateAction<AddOnsState>>;
  onDateChange: (date: Date | null) => void;
  onMonthChange: (date: Date) => void;
  onTimeSlotChange: Dispatch<SetStateAction<TimeSlotState>>;

  appointmentDuration: number;
  isAllCenterServicesValid: boolean;
  isInitialServicesSet: boolean;

  isSubmitLoading: boolean;
  setIsSubmitLoading: Dispatch<SetStateAction<boolean>>;

  storageAppointmentId: string | null;
  overriddenPrice: string | null;
  overriddenDeposit: string | null;
  overriddenTime: string | null;
  onPriceOverride: (newPrice: string) => void;
  onDepositOverride: (newDeposit: string) => void;
  onTimeOverride: (newTime: string) => void;
}

interface AppointmentBookingContextProviderProps {
  children?: ReactNode;
}

const AppointmentBookingContext = createContext<AppointmentBookingContextProps>(
  {} as AppointmentBookingContextProps,
);

export const useAppointmentBookingContext = () =>
  useContext(AppointmentBookingContext);

export const AppointmentBookingContextProvider: FC<
  AppointmentBookingContextProviderProps
> = ({ children }) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [isInitialCenterSet, setIsInitialCenterSet] = useState(false);
  const [isInitialServicesSet, setIsInitialServicesSet] = useState(false);

  const [selectedCenter, setSelectedCenter] = useState<CenterState>(null);
  const [selectedAddOns, setSelectedAddOns] = useState<AddOnsState>([]);
  const [selectedDate, setSelectedDate] = useState<DateState>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotState>(null);
  const [selectedCenterServices, setSelectedCenterServices] =
    useState<CenterServicesState>([null]);
  const [overriddenPrice, setOverriddenPrice] = useSessionStorage<
    string | null
  >(OVERRIDDEN_PRICE_KEY, null);
  const [overriddenDeposit, setOverriddenDeposit] = useSessionStorage<
    string | null
  >(OVERRIDDEN_DEPOSIT_KEY, null);
  const [overriddenTime, setOverriddenTime] = useSessionStorage<string | null>(
    OVERRIDDEN_TIME_KEY,
    null,
  );

  const { setAlert } = useUIContext();
  const translations = useTranslations();

  const centerUserSupabaseToken = sessionStorage.getItem(
    SUPABASE_CENTER_USER_TOKEN_KEY,
  );

  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());

  const [isStorageAppointmentLoading, setIsStorageAppointmentLoading] =
    useState(false);
  const [isStorageAppointmentChecked, setIsStorageAppointmentChecked] =
    useState(false);
  const [storageAppointmentId, setStorageAppointmentId] = useState<
    string | null
  >(null);
  const { isCenterUserLoading, isCenterUser, isTokenChecked } =
    useAuthContext();

  const setAppointmentFromStorage = useCallback(async () => {
    if (isStorageAppointmentChecked) return;
    setIsStorageAppointmentChecked(true);

    try {
      setIsStorageAppointmentLoading(true);
      const storageAppointment = await getStorageAppointment();
      if (!storageAppointment) {
        setIsStorageAppointmentLoading(false);
        return;
      }

      setStorageAppointmentId(storageAppointment.id);
      setSelectedCenter(storageAppointment.center);
      setSelectedAddOns(storageAppointment.addOns);
      setSelectedDate(storageAppointment.date);
      setSelectedTimeSlot(storageAppointment.timeSlot);
      setSelectedCenterServices(storageAppointment.centerServices);

      setSelectedMonth(storageAppointment.month);
    } catch (e) {
      // eslint-disable-next-line no-empty
    } finally {
      setIsStorageAppointmentLoading(false);
    }
  }, [isStorageAppointmentChecked]);

  useEffect(() => {
    setAppointmentFromStorage();
  }, [setAppointmentFromStorage]);

  const { data: systemConfigData } = useSystemConfig();

  const centers = useBookingCenters({ systemConfig: systemConfigData });

  const offerings = useBookingOfferings({
    isCenterUser,
    centerConfig: selectedCenter,
  });

  const packages = useBookingPackages({
    centerConfig: selectedCenter,
    isCenterUser,
  });

  const centerServices = useBookingCenterServices({
    centerConfig: selectedCenter,
    isCenterUser,
  });

  const addOns = useBookingAddOns(selectedCenter?.id);

  const appointmentDuration = useMemo(() => {
    const selectedCenterServicesDurationBig = selectedCenterServices.reduce(
      (acc, selectedCenterService) => {
        return acc.plus(selectedCenterService?.durationMinutes || 0);
      },
      Big(0),
    );

    return selectedCenterServicesDurationBig.toNumber();
  }, [selectedCenterServices]);

  const availableTimeSlots = useBookingAvailableTimeSlots({
    storageAppointmentId,
    center: selectedCenter,
    date: selectedMonth,
    appointmentDuration,
  });

  const onCenterChange: AppointmentBookingContextProps["onCenterChange"] =
    useCallback(
      (value) => {
        setSelectedTimeSlot(null);
        setSelectedDate(null);
        setSelectedMonth(new Date());
        setSelectedAddOns([]);

        if (isInitialCenterSet) {
          setSelectedCenterServices([null]);
        }

        setSelectedCenter(value);

        const newParams = new URLSearchParams(searchParams);
        if (value) {
          newParams.set("location", value.id);
        } else {
          newParams.delete("location");
        }

        setSearchParams(newParams, { replace: true });
      },
      [searchParams, setSearchParams, isInitialCenterSet],
    );

  useEffect(() => {
    if (
      isInitialCenterSet ||
      (isStorageAppointmentChecked && storageAppointmentId) ||
      centers.status !== "success"
    ) {
      return;
    }
    let centerToSet: CenterState = null;
    const currentParams = new URLSearchParams(searchParams);

    if (centers.data.length === 1) {
      [centerToSet] = centers.data;
    } else {
      const searchParamsCenterId = currentParams.get("location");
      if (searchParamsCenterId) {
        centerToSet =
          centers.data.find((center) => center.id === searchParamsCenterId) ||
          null;
      }
    }

    if (centerToSet) {
      setSelectedCenter(centerToSet);

      if (currentParams.get("location") !== centerToSet.id) {
        const newParams = new URLSearchParams(searchParams);
        newParams.set("location", centerToSet.id);
        setSearchParams(newParams, { replace: true });
      }
    }

    setIsInitialCenterSet(true);
  }, [
    centers,
    searchParams,
    setSearchParams,
    centerServices,
    isInitialCenterSet,
    isStorageAppointmentChecked,
    storageAppointmentId,
  ]);

  useEffect(() => {
    if (
      !selectedCenter ||
      isInitialServicesSet ||
      centerServices.status !== "success"
    ) {
      return;
    }

    const servicesParam = searchParams.get("services");

    if (!servicesParam) {
      setIsInitialServicesSet(true);
      return;
    }

    const servicesFromUrl = servicesParam
      .split(",")
      .map((param) => {
        const [serviceId, side] = param.split(":");
        const centerService = centerServices.data[serviceId];

        if (!centerService) {
          setAlert({
            severity: "error",
            message: translations.offeringNotAvailable,
            isShown: true,
          });

          return null;
        }

        const {
          id,
          groupId,
          name,
          price,
          discountPrice,
          depositAmount,
          durationMinutes,
        } = centerService;

        return {
          value: id,
          groupId: groupId as CenterServiceGroupId,
          name,
          price,
          discountPrice,
          depositAmount,
          durationMinutes,
          ...(side && { side: side as SelectedOfferingSide }),
        };
      })
      .filter((service): service is SelectedCenterService => service !== null);

    if (servicesFromUrl.length > 0) {
      setSelectedCenterServices(servicesFromUrl);
    }
    setIsInitialServicesSet(true);
  }, [
    selectedCenter,
    centerServices,
    searchParams,
    isInitialServicesSet,
    isStorageAppointmentChecked,
    storageAppointmentId,
    setAlert,
    translations.offeringNotAvailable,
  ]);

  const onCenterServicesChange: Dispatch<SetStateAction<CenterServicesState>> =
    useCallback((value) => {
      setSelectedTimeSlot(null);
      setSelectedDate(null);
      setSelectedCenterServices(value);
    }, []);

  const onMonthChange: AppointmentBookingContextProps["onMonthChange"] =
    useCallback((value) => {
      setSelectedMonth(value);
      setSelectedTimeSlot(null);
      setSelectedDate(null);
    }, []);

  const onDateChange: AppointmentBookingContextProps["onDateChange"] =
    useCallback((value) => {
      setSelectedTimeSlot(null);
      setSelectedDate(value);
    }, []);

  const onPriceOverride = (newPrice: string) => {
    const price = newPrice.replace(/[^0-9.]/g, "");

    setOverriddenPrice(price);
  };

  const onTimeOverride = (newTime: string) => {
    setOverriddenTime(newTime);
  };

  const onDepositOverride = (newDeposit: string) => {
    const deposit = newDeposit.replace(/[^0-9.]/g, "");

    setOverriddenDeposit(deposit);
  };

  const isAllCenterServicesValid = useMemo(() => {
    const { data, status } = centerServices;

    if (status !== "success") return false;

    return selectedCenterServices.every((selectedCenterService) => {
      if (!selectedCenterService) return false;

      const centerService = data[selectedCenterService.value];

      if (!centerService) return false;

      const isCenterServiceWithDualSide =
        centerService.groupId === "single-offering" &&
        centerService.scanSide === "dual";

      if (isCenterServiceWithDualSide && !selectedCenterService.side) {
        return false;
      }

      return true;
    });
  }, [centerServices, selectedCenterServices]);

  if (
    isStorageAppointmentLoading ||
    centers.status === "loading" ||
    isCenterUserLoading ||
    !isTokenChecked ||
    centerUserSupabaseToken === undefined
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AppointmentBookingContext.Provider
      value={{
        centers,
        offerings,
        packages,
        addOns,
        availableTimeSlots,
        centerServices,
        isInitialServicesSet,

        selectedCenter,
        selectedAddOns,
        selectedDate,
        selectedTimeSlot,
        selectedCenterServices,

        selectedMonth,
        setSelectedMonth,

        onCenterChange,
        onAddOnsChange: setSelectedAddOns,
        onDateChange,
        onTimeSlotChange: setSelectedTimeSlot,
        onCenterServicesChange,
        onMonthChange,

        appointmentDuration,
        isAllCenterServicesValid,

        isSubmitLoading,
        setIsSubmitLoading,

        storageAppointmentId,
        overriddenPrice,
        overriddenDeposit,
        overriddenTime,
        onPriceOverride,
        onDepositOverride,
        onTimeOverride,
      }}
    >
      {children}
    </AppointmentBookingContext.Provider>
  );
};
